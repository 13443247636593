import request from '@/utils/request'

//政策管理一览
export function policyList(data) {
  return request({
    url: "/policy/policyList",
    method: "post",
    data
  })
}

//政策详情
export function selectPolicy(data) {
  return request({
    url: "/policy/selectPolicy",
    method: "get",
    params: data
  })
}

//根据地区id查询发布机构
export function selectInstitution(data) {
  return request({
    url: "/policy/selectInstitution",
    method: "get",
    params: data
  })
}

//新增/编辑政策/冻结与解冻
export function setPolicy(data) {
  return request({
    url: "/policy/add",
    method: "post",
    data
  })
}

//冻结与解冻
export function setFreeze(data) {
  return request({
    url: "/policy/isFreeze",
    method: "post",
    params: data
  })
}

//批量删除政策
export function deletePolicy(data) {
  return request({
    url: "/policy/delete",
    method: "post",
    data
  })
}
